import axios from "axios";
import axiosRetry from "axios-retry";

if (
    window.location.protocol !== "https:" &&
    process.env.NODE_ENV === "production" &&
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1"
) {
    window.location.replace(
        `https:${window.location.href.substring(window.location.protocol.length)}`
    );
}

export const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
    // baseURL: "https://api-dev.cirurgiaautorizada.com.br/api",
    // baseURL: 'http://127.0.0.1:8000/api',
    headers: {
        "Content-Type": "application/json",
    },
});

axiosApi.interceptors.request.use(
    config => {
      const token = localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : "";
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

axiosApi.interceptors.response.use((response) => response, async (error) => {
    console.log("chamando interceptor")
    if (error.response.status === 401 && window.location.href.includes('/login') === false) {
        console.log("executando interceptor")
        
        await axiosApi.post("/auth/refresh")
        .then((response) => {
            const { access_token, expires_in } = response.data;
            const dataExpiracao = new Date(
                new Date().getTime() + response.data.expires_in
            );
            localStorage.setItem("token", access_token);
            localStorage.setItem("expires_in", expires_in);
            localStorage.setItem("data_expiracao", JSON.stringify(dataExpiracao));
            return axiosApi.request(error.config);
          })
        .catch((error) => {
            localStorage.clear();
            window.location = '/quem-sou';
        });
    } else {
        return Promise.reject(error);
    }
  });

/* const errorInterceptor = axiosApi.interceptors.response.use(
    function (response) {
        return response;
    }, function (error) {
        console.log(error.response.status, error.response.data);
        return Promise.reject(error);;
    }); */

export const ativarRefreshToken = (setDadosAtualizados) => {
    console.log([
        new Date(JSON.parse(localStorage.getItem("data_expiracao"))),
        new Date(),
    ]);
    if (
        localStorage.getItem("data_expiracao") &&
        new Date(JSON.parse(localStorage.getItem("data_expiracao"))) >= new Date()
    ) {
        const expires_in = localStorage.getItem("expires_in");
        setInterval(async() => {
            const response = await axiosApi.get("/auth/refresh");
            const { access_token, expires_in } = response.data;
            const dataExpiracao = new Date(
                new Date().getTime() + response.data.expires_in
            );
            localStorage.setItem("token", access_token);
            localStorage.setItem("expires_in", expires_in);
            localStorage.setItem("data_expiracao", JSON.stringify(dataExpiracao));
            axiosApi.defaults.headers.common[
                "authorization"
            ] = `bearer ${access_token}`;
            setDadosAtualizados(
                access_token,
                JSON.parse(localStorage.getItem("usuario") ? localStorage.getItem("usuario") : "null"),
                expires_in
            );
        }, expires_in - 90000);
    } else {
        localStorage.clear();
        window.location.reload();
    }

    // const expires_in = localStorage.getItem("expires-in");
    // setTimeout(async () => {
    //   const response = await axiosApi.get("/auth/refresh");
    //   const { access_token, expires_in } = response.data;
    //   localStorage.setItem("token", access_token);
    //   localStorage.setItem("expires-in", expires_in);
    //   axiosApi.defaults.headers.common[
    //     "authorization"
    //   ] = `bearer ${access_token}`;
    //   setToken(access_token);
    // }, expires_in);
};

axiosRetry(axiosApi, { retries: 3 });
import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Login, Paciente, Secretaria, Home } from "./pages";
import IntroLayout from "./pages/Layouts/Intro";
import HomeLayout, { MobileHomeLayout } from "./pages/Layouts/Home";
import { AuthState } from "./store/types";
import { connect, ConnectedProps } from "react-redux";
import Logout from "./pages/Logout";
import Calendario from "./pages/Calendario";
import Autorizacoes from "./pages/Autorizacoes";
import Fornecedor from "./pages/Fornecedor";
import SelectPage from "./pages/SelectAccess";
import Auxiliares from "./pages/Auxiliares";
import HospitaisPage from "./pages/Hospitais";
import FornecedoresPage from "./pages/Fornecedores";
import RecuperarSenha from "./pages/RecuperarSenha";
import Usuarios from "./pages/Usuarios";
import Admin from "./pages/Admin";
import { MobileHome } from "./pages/Home";
import MobileAuxiliaries from "./pages/MobileAuxiliaries";
import MobileAuthorizations from "./pages/MobileAuthorizations";
import MobileCalendario from "./pages/MobileCalendario";
import Hospital from "./pages/Hospital";
import EquipeMedica from "./pages/EquipeMedica";
import SolicitarCirurgia from "./pages/SolicitarCirurgia";
import Relatorio from "./pages/Relatorio";
import Acompanhamento from "./pages/Acompanhamento/Acompanhamento";
// import Participacoes from "./pages/EquipeMedica/ListaEquipeMedica";
// import Pendentes from "./pages/EquipeMedica/Pendentes";

const mapStateToProps = ({ auth }: { auth: AuthState }) => {
  return {
    token: auth.token,
  };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

function Rotas({ token }: Props): JSX.Element {
  return token ? (
    <Router>
      {
        window.innerWidth > 832?
          <HomeLayout>
            <Routes>
              <Route element={<Home />} path="/"/>
              <Route path="/home" element={<Home />} />
              <Route path="/calendario" element={<Calendario />} />
              <Route path="/autorizacoes" element={<Autorizacoes />} />
              <Route path="/auxiliares" element={<Auxiliares />} />
              <Route path="/hospitais" element={<HospitaisPage />} />
              <Route path="/equipe-medica//*" element={<EquipeMedica />} />
              <Route path="/relatorio" element={<Relatorio />} />
              <Route path="autorizacoes/solicitar-cirurgia//*" element={<SolicitarCirurgia />} />
              <Route path="/fornecedores" element={<FornecedoresPage />} />
              <Route path="/usuarios" element={<Usuarios id={0} />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </HomeLayout>
        : 
        window.innerWidth > 414 && window.innerWidth <= 832 ?
        <MobileHomeLayout>
          <Routes>
            <Route path="/home" element={<MobileHome />}/>
            <Route path="/auxiliares" element={<MobileAuxiliaries />}/>
            <Route path='/autorizacoes' element={<MobileAuthorizations />}/>
            <Route path="/calendario" element={<MobileCalendario />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<MobileHome />} />
          </Routes>
        </MobileHomeLayout>
        : 
        <MobileHomeLayout>
          <Routes>
            <Route path="/home" element={<MobileHome />}/>
            <Route path="/auxiliares" element={<MobileAuxiliaries />}/>
            <Route path='/autorizacoes' element={<MobileAuthorizations />}/>
            <Route path="/calendario" element={<MobileCalendario />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<MobileHome />} />
          </Routes>
        </MobileHomeLayout>
      }
    </Router>
  ) : (
    <Router>
        <IntroLayout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/solicitacao" element={<Paciente />} />
            <Route path="/secretaria" element={<Secretaria />} />
            <Route path="/hospital" element={<Hospital />} />
            <Route path="/fornecedor" element={<Fornecedor />} />
            <Route path="/quem-sou" element={<SelectPage />} />
            <Route path="/acompanhamento" element={<Acompanhamento />} />
            <Route path="/recuperar-senha" element={<RecuperarSenha />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="*" element={<SelectPage />} />
          </Routes>
        </IntroLayout>
    </Router>
  );
}

export default connector(Rotas);

import React from "react";
import Titulo from "../SolicitarCirurgia/Titulo";
import DoutorForm from "./Forms/DoutorForm";
import { connect } from "react-redux";
import { Usuario } from "src/store/types";
import ButtonGerarRelatorio from "./ButtonGerarRelatorio";
import FornecedorForm from "./Forms/FornecedorForm";
import HospitalForm from "./Forms/HospitalForm";
import AdminForm from "./Forms/AdminForm";

interface Props {
  usuario: Usuario;
}

const RelatorioLayout: React.FC<Props> = ({ usuario }) => {

  const hospitalCheck = usuario.nivel === 6
  const fornecedorCheck = usuario.nivel === 2;
  const adminCheck = usuario.nivel === 5;
  const doutorCheck = usuario.nivel === 1;

  return (
    
    <>
      <div style={{ width: 800, top: 130, left: 60 }} className="relative">
        <Titulo Content={`Relatório`} />
        {doutorCheck && <DoutorForm />}
        {fornecedorCheck && <FornecedorForm />}
        {hospitalCheck && <HospitalForm />}
        {adminCheck && <AdminForm />}
        <ButtonGerarRelatorio />
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  usuario: state.auth.usuario,
});

export default connect(mapStateToProps)(RelatorioLayout);
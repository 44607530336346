import React from "react"

const ButtonGerarRelatorio: React.FC = () => {

  return (

    <>
      <div
        style={{ width: 410 }}
        className="flex justify-end py-5">
        <button
          style={{ width: 160 }}
          className="btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full"
          onClick={() => console.log('Gerar Relatorio')}
        >
          Gerar Relatório
        </button>
      </div>
    </>
  )
}



export default ButtonGerarRelatorio;
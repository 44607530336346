import React, { useEffect, useState } from 'react';
import './style.css';
import ModalAssinaturaDigital from '../ModalAssinaturaDigital';
import { axiosApi } from 'src/services/axiosInstances';


interface AssinaturaDigitalProps {
  certificado?: boolean;
}

interface ExpiresIn {
  days: number;
  hours: number;
}

const calculateTimeLeft = (expirationDate: string): ExpiresIn => {
  if (!expirationDate) return { days: 6, hours: 23 };

  const now = new Date();
  const expiry = new Date(expirationDate);
  const difference = expiry.getTime() - now.getTime();

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  return {
    days: Math.max(days, 0),
    hours: Math.max(hours, 0),
  };
};

const AssinaturaDigital: React.FC<AssinaturaDigitalProps> = ({ certificado }) => {
  const [toggled, setToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [certificadorId, setCertificadorId] = useState(0);
  const [expiresIn, setExpiresIn] = useState<ExpiresIn>({ days: 6, hours: 23 });
  const [jsonDate, setJsonDate] = useState('');

  const closeModal = () => {
    checkCertificate();
    // console.log(`teste`)
    // setToggled(false);
    setIsOpen(false);
  }

  const toggleButton = () => {
    setToggled(true);
    setIsOpen(true);
  }

  const checkCertificate = async () => {
    try {
      const response = await axiosApi.get('/certificado');
      setJsonDate(response.data.expires_in);
      if(response.data.certificador_id) {
        setToggled(true);
        setCertificadorId(response.data.certificador_id);
      } else {
        setToggled(false);
      }
      console.log(`bing`);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (jsonDate) {
      const timeLeft = calculateTimeLeft(jsonDate);
      setExpiresIn(timeLeft);
    }
  }, [jsonDate]);

  useEffect(() => {
    if (certificado !== undefined) {
      setToggled(certificado);
    }
  }, [certificado]);

  useEffect(() => {
    checkCertificate();
  }, []);

  // useEffect(() => {
  //   if (certificadorId) {
  //     // console.log(certificadorId)
  //     setToggled(true);
  //   }
  // }, [certificadorId]);

  // useEffect(() => {
  //   const assinaturaDigital = localStorage.getItem('assinaturaDigital');
  //   if (assinaturaDigital) {
  //     setToggled(JSON.parse(assinaturaDigital));
  //   }
  // }, []);

  return (
    <div className='flex items-center'>
      <div className={`toggleClass mr-2 ${toggled ? 'toggle-div' : ''}`}>
        <div className={`toggle-button ${toggled ? 'active' : ''}`} onClick={toggleButton}></div>
      </div>
      <h2 className='items-center'>Assinatura Digital</h2>
      <ModalAssinaturaDigital
        isOpen={isOpen}
        closeModal={closeModal}
        certificadoIsActive={certificado}
        certificadorId={certificadorId}
        setToggled={setToggled}
        setCertificadorId={setCertificadorId}
        expiresIn={expiresIn}
        checkCertificate={checkCertificate}
      />
    </div>
  )
}

export default AssinaturaDigital;

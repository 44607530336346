import React from "react"

const AdminForm: React.FC = () => {

  return (

    <>
      <form>
        <div className="flex flex-col mt-5 gap-y-5">
          <div className="flex gap-5 items-center">
            <div className="flex flex-col items-center">
              <label className="text-black pb-2" htmlFor="data-inicial">Data inicial*</label>
              <input
                id="data-inicial"
                style={{ width: 195 }}
                className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
                placeholder="" type="date"
              // {...register("nome")}
              />
              {/* {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>} */}
            </div>
            <div className="flex flex-col items-center">
              <label className="text-black pb-2" htmlFor="data-final">Data final*</label>
              <input
                id="data-final"
                style={{ width: 195 }}
                className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
                placeholder="" type="date"
              // {...register("nome")}
              />
              {/* {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>} */}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-black pb-2" htmlFor="descritivo">Cirurgia</label>
            <input
              id="descritivo"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              placeholder="" type="text"
            // {...register("nome")}
            />
            {/* {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>} */}
          </div>

          <div className="flex flex-col">
            <label className="text-black pb-2" htmlFor="fornecedor">Cirurgão</label>
            <input
              id="cirurgiao"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              placeholder="" type="text"
            // {...register("nome")}
            />
            {/* {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>} */}
          </div>

          <div className="flex flex-col">
            <label className="text-black pb-2" htmlFor="hospital">Hospital</label>
            <input
              id="hospital"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              placeholder="" type="text"
            // {...register("nome")}
            />
            {/* {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>} */}
          </div>


          <div className="flex flex-col">
            <label className="text-black pb-2" htmlFor="participacao">Fornecedor</label>
            <input
              id="fornecedor"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              placeholder="" type="text"
            // {...register("nome")}
            />
            {/* {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>} */}
          </div>

        </div>
      </form>
    </>
  )
}

export default AdminForm;